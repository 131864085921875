import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ButtonBase from "@material-ui/core/ButtonBase";
import MenuPages from "../components/MenuPages";
import { navigate } from "gatsby";
import Home from "@material-ui/icons/Home";

const drawerWidth = 235;
const appBarColor = "#1976D2";

let anchorPagesMenu: HTMLElement;

const useStyles = makeStyles(() => ({
  iconScheme1: {
    fontSize: "1.7rem",
    color: "#eeeeee"
  },
  iconButtonRight: {
    padding: "8px"
  },
  iconsWrapper: {
    marginLeft: "auto",
    padding: "0px",
    marginRight: "15px",
    ["@media (min-width:780px)"]: {
      marginRight: "35px"
    }
  },
  appbarTitle: {
    letterSpacing: "0.010rem",
    fontSize: "1.6rem",
    marginLeft: "5px",
    ["@media (min-width:780px)"]: {
      marginLeft: "50px"
    },
    ["@media (min-width:1024px)"]: {
      marginLeft: "100px"
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    backgroundColor: appBarColor
  },
  iconButtonLeft: {
    marginLeft: "5px"
  }
}));

interface AppBarPageProps {
  title: string;
}

function AppBarPage(props: AppBarPageProps) {
  const classes = useStyles(props);

  const [isPagesMenuOpen, setIsPagesMenuOpen] = React.useState(false);

  function handlePagesMenuClick(event: React.MouseEvent<HTMLElement>) {
    anchorPagesMenu = event.currentTarget;
    setIsPagesMenuOpen(true);
  }

  function handleGoHome() {
    navigate("/");
  }

  function gotoNewUrl(url: string) {
    setIsPagesMenuOpen(false);
    navigate(url);
  }

  return (
    <div id="app-bar">
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton size="medium" color="inherit" edge="start" onClick={handleGoHome} className={classes.iconButtonLeft}>
            <Home className={classes.iconScheme1} />
          </IconButton>

          <ButtonBase onClick={() => (document.location.href = "/")}>
            <Typography className={classes.appbarTitle} noWrap>
              {props.title}
            </Typography>
          </ButtonBase>

          <div className={classes.iconsWrapper}>
            {/* Pages Menu Button */}
            <IconButton
              size="medium"
              color="inherit"
              aria-label="Menu"
              edge="start"
              onClick={handlePagesMenuClick}
              className={classes.iconButtonRight}
            >
              <MenuIcon className={classes.iconScheme1} />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <MenuPages
        isOpen={isPagesMenuOpen}
        onClose={() => setIsPagesMenuOpen(false)}
        isSharingAvailable={false}
        anchorEl={anchorPagesMenu}
        isShowBookmarkEntries={false}
        onNewUrl={gotoNewUrl}
      />
    </div>
  );
}

export default AppBarPage;
