import React from "react";
import ContactForm from "../components/ContactForm";
import { makeStyles } from "@material-ui/core/styles";
import AppBarPage from "../components/AppBarPage";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Helmet } from "react-helmet";

const appbarHeight = 64;
const verticalOffset = 6;

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: "650px",
    margin: "auto",
    marginTop: appbarHeight + verticalOffset + 18,
    padding: "0px 8px 0px 8px" // somehow, 8 pixels margin is applied bo <body> by somebody
  },
  title: {
    marginBottom: "7px",
    marginLeft: "3px"
  }
}));

function Contact() {
  const classes = useStyles("");

  return (
    <div>
      <AppBarPage title="CloudQuran" />
      <div className={classes.container}>
        <Typography variant="h6" className={classes.title}>
          Contact Us
        </Typography>
        <ContactForm />
        <Box m={8} />
      </div>
      <Helmet>
        {/* meta tags */}
        <title>Contact CloudQuran - Quran in Arabic</title>
        <meta name="description" content="Contact CloudQuran - Quran in Arabic" />
        <meta name="author" content="Talal Itani" />
        <meta name="robots" content="index, follow" />
      </Helmet>
    </div>
  );
}

export default Contact;
