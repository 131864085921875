import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const blueColor = "#1f4c9f";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: blueColor,
      dark: blueColor
    }
  }
});

const useStyles = makeStyles(theme => ({
  containerTop: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginRight: theme.spacing(2)
  },
  textFieldMessage: {
    width: "100%"
  },
  submitButton: {
    marginTop: "12px",
    float: "right"
  },
  hidden: {
    display: "none"
  }
}));
function ContactForm() {
  const classes = useStyles("");

  return (
    <MuiThemeProvider theme={theme}>
      <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field" action="/success">
        <input type="hidden" name="form-name" value="contact" />
        <div className={classes.containerTop}>
          <TextField name="subject" value="Contact CloudQuran" className={classes.hidden} />
          <TextField
            required
            name="name"
            label="Name"
            defaultValue=""
            className={classes.textField}
            margin="dense"
            variant="outlined"
            autoComplete="name"
          />
          <TextField
            required
            name="email"
            label="Email"
            defaultValue=""
            className={classes.textField}
            type="email"
            margin="dense"
            variant="outlined"
            autoComplete="email"
          />
        </div>
        <TextField
          required
          name="message"
          label="Message"
          defaultValue=""
          className={classes.textFieldMessage}
          multiline
          rows="7"
          margin="dense"
          variant="outlined"
        />
        <Button variant="contained" color="primary" className={classes.submitButton} type="submit">
          &nbsp;Submit&nbsp;
        </Button>
      </form>
    </MuiThemeProvider>
  );
}

export default ContactForm;
